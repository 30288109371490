import { Layout, Menu, Breadcrumb } from "antd";
import React, { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import { useStoreActions, useStoreState } from "./store/hooks/easyPeasy";
import {
  AppstoreOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";
import { ROUTES } from "./contents/routes";
import TopNavBar from "./topNavbar";
import { Sidebar } from "./layouts/sidebar/Sidebar";
import { Link } from "react-router-dom";
import Users from "./container/pages/user/Users";

import Dashboard from './container/pages/dashboard/Dashboard.page';

import BookCategory from "./container/pages/library/BookCategory";

import LedgerList from "./container/pages/GeneralAccounts/ledger/LedgerList";
import Receive from "./container/pages/GeneralAccounts/transaction/Receive";
import Payment from "./container/pages/GeneralAccounts/transaction/Payment";
import Contra from "./container/pages/GeneralAccounts/transaction/Contra";
import Journal from "./container/pages/GeneralAccounts/transaction/Journal";
import Voucher from "./container/pages/GeneralAccounts/delete/Voucher";
import TrialBalance from "./container/pages/GeneralAccounts/report/TrialBalance";
import BalanceSheet from "./container/pages/GeneralAccounts/report/BalanceSheet";
import IncomeStatement from "./container/pages/GeneralAccounts/report/IncomeStatement";
import JournalR from "./container/pages/GeneralAccounts/report/Journal";
import FundsFlow from "./container/pages/GeneralAccounts/report/FundsFlow";
import CashSummary from "./container/pages/GeneralAccounts/report/CashSummary";
import AccountsBookTab from "./container/pages/GeneralAccounts/report/AccountsBookTab";
import UserWiseTransaction from "./container/pages/GeneralAccounts/report/UserWiseTransaction";
import VoucherTemplate from "./container/pages/GeneralAccounts/report/VoucherTemplate";
import FeeSubHeadCollectionDetails from "./container/pages/StudentAccounts/report/FeeSubHeadCollectionDetails";
import FeeHead from "./container/pages/StudentAccounts/settings/FeeHead.page";
import FeeSubHead from "./container/pages/StudentAccounts/settings/FeeSubHead.page";
import FeeWaiver from "./container/pages/StudentAccounts/settings/FeeWaiver.page";
import FeeHeadLedger from "./container/pages/StudentAccounts/configuration/FeeHeadLedger.page";
import FeeSubHeadLedger from "./container/pages/StudentAccounts/configuration/FeeSubHeadLedger.page";
import OFPS from "./container/pages/StudentAccounts/configuration/OFPS";
import FeeFineLedger from "./container/pages/StudentAccounts/configuration/FeeFineLedger.page";
import FeeAmountConfig from "./container/pages/StudentAccounts/configuration/FeeAmountConfig.page";
import FeeSubHeadTimeConfig from "./container/pages/StudentAccounts/configuration/FeeSubHeadTimeConfig.page";
import FeeWaiverConfig from "./container/pages/StudentAccounts/configuration/FeeWaiverConfig.page";
import FeeHeadDelete from "./container/pages/StudentAccounts/configuration/FeeHeadDelete.page";
import FeesCollection from "./container/pages/StudentAccounts/feescollection/FeesCollection.page";
import SectionPaidInfo from "./container/pages/StudentAccounts/report/SectionPaidInfo";
import ClassPaidInfo from "./container/pages/StudentAccounts/report/ClassPaidInfo";
import AllPaidInfo from "./container/pages/StudentAccounts/report/AllPaidInfo";
import SectionDueDetails from "./container/pages/StudentAccounts/report/SectionDueDetails";
import MonthlyDueDetails from "./container/pages/StudentAccounts/report/MonthlyDueDetails";
import FeeHeadCollection from "./container/pages/StudentAccounts/report/FeeHeadCollection";
import SpgStatement from "./container/pages/StudentAccounts/report/SpgStatement";
import SpgSummary from "./container/pages/StudentAccounts/report/SpgSummary";
import BkashStatement from "./container/pages/StudentAccounts/report/BkashStatement";
import BkashSummary from "./container/pages/StudentAccounts/report/BkashSummary";
import FeeHeadCollectionClass from "./container/pages/StudentAccounts/report/FeeHeadCollectionClass";
import StudentGivenWaiver from "./container/pages/StudentAccounts/report/StudentGivenWaiverReport";
import FeeHeadCollectionDateToDateAllSecion from "./container/pages/StudentAccounts/report/FeeHeadCollectionDateToDateAllSection";
import PremierBankSslStatement from "./container/pages/StudentAccounts/report/PremierBankSslStatement";

import Invoice from "./container/pages/StudentAccounts/delete/Invoice";
import AttendanceFineWrapper from "./container/pages/StudentAccounts/configuration/AttendanceFineWrapper";
import IndividualFeeAssignWrapper from "./container/pages/StudentAccounts/configuration/IndividualFeeAssignWrapper";


import StudentWrapper from "./container/pages/Students/settings/StudentWrapper.page";
import CalssConfiguration from "./container/pages/Students/configuration/ClassConfiguration.page";
import GroupConfiguration from "./container/pages/Students/configuration/GroupConfiguration.page";
import GroupConfigurationCustomerSupport from "./container/pages/Students/configuration/GroupConfiurationCustomerSupport";
import MultipleRegistrationWrapper from "./container/pages/Students/registration/MultipleRegistrationWrapper";
import MultipleRegistrationExeclWrapper from "./container/pages/Students/registration/MultipleRegistrationExeclWrapper";
import StudentMigration from "./container/pages/Students/registration/StudentMigration";
import PhotoUpdate from "./container/pages/Students/registration/PhotoUpdate";
import AcademicInfo from "./container/pages/Students/registration/AcademicInfo"; //AddressInfoUpdate
import AddressInfoUpdate from "./container/pages/Students/registration/AddressInfoUpdate";

import StaffSettings from "./container/pages/Staff/settings/StaffSettings.page";
import SingleRegistration from "./container/pages/Staff/registration/SingleRegistration.page";
import MultipleRegistrationStaff from "./container/pages/Staff/registration/MultipleRegistration.page";
import StaffUpdate from "./container/pages/Staff/update/StaffUpdate.page";
import StaffUpdateSingle from "./container/pages/Staff/update/StaffUpdateSingle.page";
import BasicUpdate from "./container/pages/Students/registration/BasicUpdate";
import BasicUpdateInternational from "./container/pages/Students/registration/BasicUpdateInternational";
import StudentProfileUpdate from "./container/pages/Students/Update/StudentProfileUpdate";
import StaffAssign from "./container/pages/Staff/assign/StaffAssign.page";
import StaffSubjectAssignWrapper from "./container/pages/Staff/assign/StaffSubjectAssignWrapper.page";
import StaffList from "./container/pages/Staff/report/StaffList.page";
import StaffIdCardTemplate from "./container/pages/Staff/idCard/StaffIdCardTemplate";
import MarkProcessClass from "./container/pages/Exam/configuration/MarkProcessClass";
import ExamSettingsWrapper from "./container/pages/Exam/setting/ExamSettingsWrapper";
import ExamSubject from "./container/pages/Exam/configuration/ExamSubject";
import Exam from "./container/pages/Exam/setting/Exam";
import ExamConfiguration from "./container/pages/Exam/configuration/ExamConfiguration";
import ExamReportBlankSheet from "./container/pages/Exam/reports/ExamReportBlankSheet";
import ExamRoutineWrapper from "./container/pages/Exam/configuration/ExamRoutineWrapper";
// import Mark from "./container/pages/Exam/configuration/Mark";/
import MarMarkConfigk from "./container/pages/Exam/configuration/MarkConfigPage"
import GrandFinalMark from "./container/pages/Exam/configuration/GrandFinalMark";
import ExamFortSubject from "./container/pages/Exam/configuration/ExamFortSubject";
import AdmitCardInstruction from "./container/pages/Exam/configuration/AdmitCardInstruction";
import ExamTemplate from "./container/pages/Exam/configuration/template/ExamTemplate";
import AdmitCardSeatPlanWrapper from "./container/pages/Exam/configuration/AdmitCardSeatPlanWrapper";
import MarkInputForm from "./container/pages/Exam/configuration/MarkInputForm";
import MarkUpdateForm from "./container/pages/Exam/configuration/MarkUpdateForm";
import MarkInputSpecial from "./container/pages/Exam/configuration/MarkInputSpecial";
import MarkUpdateSpecial from "./container/pages/Exam/configuration/MarkUpdateSpecial";
import MarkSheetSpecial from "./container/pages/Exam/reports/MarkSheetSpecial";
import MarkInputFormSubject from "./container/pages/Exam/configuration/MarkInputFormSubject";
import MarkUpdateFormSubject from "./container/pages/Exam/configuration/MarkUpdateFormSubject";
import MarkDelete from "./container/pages/Exam/configuration/MarkDelete";
import GeneralExam from "./container/pages/Exam/configuration/process/GeneralExam";
import GrandFinal from "./container/pages/Exam/configuration/process/GrandFinal";
import MeritPostion from "./container/pages/Exam/configuration/process/MeritPostion";
import ExamRemarks from "./container/pages/Exam/configuration/ExamRemarks";
import TabulationSheet from "./container/pages/Exam/configuration/TabulationSheet";
import GeneralMarkSheetDownload from "./container/pages/Exam/configuration/GeneralMarkSheetDownload";
import GrandFinalMarkSheetDownload from "./container/pages/Exam/configuration/GrandFinalMarkSheetDownload";
import ExamReportSectionWiseUninputtedSubjects from "./container/pages/Exam/reports/ExamReportSectionWiseUninputtedSubjects";
import ExamReportSectionWiseResult from "./container/pages/Exam/reports/ExamReportSectionWiseResult";
import SectionWiseMeritList from "./container/pages/Exam/reports/SectionWiseMeritList";
import ClassWiseMeritList from "./container/pages/Exam/reports/ClassWiseMeritList";
import GroupWiseMeritList from "./container/pages/Exam/reports/GroupWiseMeritList";
import ClassWiseFailedList from "./container/pages/Exam/reports/ClassWiseFailedList";
import SectionWiseFailedList from "./container/pages/Exam/reports/SectionWiseFailedList";
import SectionWiseGradingSummary from "./container/pages/Exam/reports/SectionWiseGradingSummary";
import ExamReportSubjectGrading from "./container/pages/Exam/reports/ExamReportSubjectGrading";
import SectionWisePassFailSummary from "./container/pages/Exam/reports/SectionWisePassFailSummary";
import ResultPublicationWrapper from "./container/pages/Exam/configuration/ResultPublicationWrapper";
import MarkProblemDelete from "./container/pages/Exam/configuration/MarkProblemDelete";


import Sms from "./container/pages/Sms/DynamicSms";
import SmsPurchaseLog from "./container/pages/Sms/SmsPurchaseLog";
import DynamicSms from "./container/pages/Sms/DynamicSms";
import StaffSms from "./container/pages/Sms/StaffSms";
import ClassWiseSms from "./container/pages/Sms/studentSms/ClassWiseSms";
import SectionWiseSms from "./container/pages/Sms/studentSms/SectionWiseSms";
import ExamResultSms from "./container/pages/Sms/studentSms/ExamResultSms";
import InstiuteSms from "./container/pages/Sms/studentSms/InstiuteSms";
import SectionDueSms from "./container/pages/Sms/studentSms/SectionDueSms";
import SmsTemplates from "./container/pages/Sms/SmsTemplates";
import GlobalSmsTemplate from "./container/pages/Sms/GlobalSmsTemplate";
import PurchaseSms from "./container/pages/Sms/PurchaseSms";
import SmsBalance from "./container/pages/Sms/report/SmsBalance";
import GeneralMessageList from "./container/pages/Sms/report/GeneralMessageList";
import StaffMessageList from "./container/pages/Sms/report/StaffMessageList";
import StudentMessageList from "./container/pages/Sms/report/StudentMessageList";
import RegistrationSms from "./container/pages/Sms/studentSms/RegistrationSms";



import InstituteUpdate from "./container/pages/masterSetting/InstituteUpdate";
import DefaultSignature from "./container/pages/masterSetting/DefaultSignature";
import UsersList from "./container/pages/masterSetting/UsersList";
import PayableBill from "./container/pages/masterSetting/PayableBill";
import PaidBill from "./container/pages/masterSetting/PaidBill";
import Tetimonial from "./container/pages/tesimonial/Tetimonial";
import TransferCertificate from "./container/pages/tesimonial/TransferCertificate";
import InstiuteList from "./container/pages/customerSupport/InstiuteList";
import InstituteJump from "./container/pages/customerSupport/InstituteJump";
import ExamGradeUpdate from "./container/pages/customerSupport/ExamGradeUpdate";
import StudentDelete from "./container/pages/Students/delete/StudentDelete";
import IdCardStudentInfo from "./container/pages/Students/idCard/IdCardStudentInfo";
import IdCardTemplate from "./container/pages/Students/idCard/IdCardTemplate";
import IdCardTemplateDownload from "./container/pages/Students/idCard/IdCardTemplateDownload";
import ClassWiseReport from "./container/pages/Students/reports/ClassWiseReport";
import SectionWiseReport from "./container/pages/Students/reports/SectionWiseReport";
import SectionWiseReportInternational from "./container/pages/Students/reports/SectionWiseReportInternational";
import SubjectWiseReport from "./container/pages/Students/reports/SubjectWiseReport";
import GroupWiseReport from "./container/pages/Students/reports/GroupWiseReport";
import TaughtListReport from "./container/pages/Students/reports/TaughtListReport";
import SectionWiseSummaryReport from "./container/pages/Students/reports/SectionWiseSummaryReport";
import StudentOverViewReportReport from "./container/pages/Students/reports/StudentOverViewReportReport";
import StudentProfileReport from "./container/pages/Students/reports/StudentProfileReport";
import MoneyReciptTemplate from "./container/pages/StudentAccounts/settings/MoneyReciptTemplate";
import ClassRoutine from "./container/pages/routine/ClassRoutine";
import ExamRoutine from "./container/pages/routine/ExamRoutine";
import TakeAttendanceSave from "./container/pages/StaffAttendance/TakeAttendanceSave";
import UpdateAttendance from "./container/pages/StaffAttendance/UpdateAttendance";
import TakeSingleLeave from "./container/pages/StaffAttendance/TakeSingleLeave";
import StaffTimeConfig from "./container/pages/StaffAttendance/StaffTimeConfig";
import StaffIdMappingWrapper from "./container/pages/StaffAttendance/StaffIdMappingWrapper";
import StaffAttendanceTimeConfigReport from "./container/pages/StaffAttendance/StaffAttendanceTimeConfigReport";
import StaffAttendanceMachine from  "./container/pages/StaffAttendance/StaffAttendanceMachine";
import StaffAttendanceDateWiseReport from "./container/pages/StaffAttendance/StaffAttendanceDateWiseReport";
import StaffAttendanceMonthWiseReport from "./container/pages/StaffAttendance/StaffAttendanceMonthWiseReport";
import StaffAttendanceDateWiseRemark from "./container/pages/StaffAttendance/StaffAttendanceDateWiseRemark";
import HolidayWrapper from "./container/pages/StaffAttendance/holiday/HolidayWrapper";
import StaffFileUpload from "./container/pages/StaffAttendance/StaffFileUpload";
import TakePeriod from "./container/pages/Students/attendance/TakePeriod.page";
import PeriodTimeConfiguration from "./container/pages/Students/attendance/PeriodTimeConfiguration.page";
import DeviceIdMapping from "./container/pages/Students/attendance/DeviceIdMapping.page";
import TakeAttendance from "./container/pages/Students/attendance/TakeAttendance.page";
import TakeAttendanceUpdate from "./container/pages/Students/attendance/TakeAttendanceUpdate.page";
import ExamAttendance from "./container/pages/Students/attendance/ExamAttendance";
import LeaveInput from "./container/pages/Students/attendance/LeaveInput.page";
import DailySectionReport from "./container/pages/Students/attendance/DailySectionReport.page";
import SectionWiseDailySummary from "./container/pages/Students/attendance/SectionWiseDailySummary.page";
import SectionWiseMonthlyDetails from "./container/pages/Students/attendance/SectionWiseMonthlyDetails.page";
import SectionWiseSummaryAttendance from "./container/pages/Students/attendance/SectionWiseSummaryAttendance.page";
import SectionWiseMonthlyDetailsSummary from "./container/pages/Students/attendance/SectionWiseMonthlyDetailsSummary.page";
import StudentFileUpload from "./container/pages/Students/attendance/StudentFileUpload";
import SingleSearchStudent from "./container/pages/Students/search/SingleSearchStudent";
import logo from "../assets/images/shebaShikkhaWhiteLogo.png";
import StudentIDUpdate from "./container/pages/Students/Update/StudentIDUpdate";
import StudentRollUpdateExcel from "./container/pages/Students/Update/StudentRollUpdateExcel";
import StudentEnableDisable from "./container/pages/Students/Update/StudentEnableDisable";
import StudentMigrationGeneral from "./container/pages/Students/migration/StudentMigrationGeneral";
import StudentMigrationExcel from "./container/pages/Students/migration/StudentMigrationExcel";
import StudentMigrationMerit from "./container/pages/Students/migration/StudentMigrationMerit";
import OfpsProblemView from "./container/pages/customerSupport/OFPSProblem/OfpsProblemView";

import FeeDiscardWrapper from "./container/pages/StudentAccounts/configuration/FeeDiscardWrapper";
import FeeAmounView from "./container/pages/StudentAccounts/report/FeeAmounView";
import UpayPgwStatement from "./container/pages/StudentAccounts/report/UpayPgwStatement";
import UpayPgwSummary from "./container/pages/StudentAccounts/report/UpayPgwSumary";
import UpayPayBillProblem from "./container/pages/customerSupport/OFPSProblem/UpayPayBillProblem";


import FeeSubHeadDiscardWrapper from "./container/pages/StudentAccounts/configuration/FeeSubHeadDiscardWrapper";
import PaySlipCreateSingle from "./container/pages/PaySlip/PaySlipCreateSingle";
import CreatePaySlipBatch from "./container/pages/PaySlip/CreatePaySlipBatch.page";
import CollectPaySlip from "./container/pages/PaySlip/CollectPaySlip.page";
import PayslipTemplate from "./container/pages/PaySlip/PayslipTemplate.page";
import { Footer } from "antd/lib/layout/layout";
import MarkConfig from "./container/pages/Exam/configuration/MarkConfigPage";
import MarkConfigPage from "./container/pages/Exam/configuration/MarkConfigPage";
import OnlineAdmissionSectionWise from "./container/pages/onlineAdmissaion/OnlineAdmissionSectionWise";
import OnlineAdmissionGroupWise from "./container/pages/onlineAdmissaion/OnlineAdmissionGroupWise";
import OnlineAdmissionPaymentStatusWise from "./container/pages/onlineAdmissaion/OnlineAdmissionPaymentStatusWise";

import OnlineAdmissionReportPaidUnpaid from "./container/pages/onlineAdmissaion/OnlineAdmissionReportPaidUnpaid";
import OnlineAdmissionGeneralReport from "./container/pages/onlineAdmissaion/OnlineAdmissionGeneralReport";
import OnlineAdmissionSpgProblemView from "./container/pages/onlineAdmissaion/OnlineAdmissionSpgProblemView";
import OnlineAdmissionStudentRegistration from "./container/pages/onlineAdmissaion/OnlineAdmissionStudentRegistration";
import OnlineAdmissionInfoEdit from "./container/pages/onlineAdmissaion/OnlineAdmissionInfoEdit";

import AlumniStudentView  from "./container/pages/AlumniStudent/AlumniStudentView";
import AlumniSpgProblemView  from "./container/pages/AlumniStudent/AlumniSpgProblemView";

import FeePaidSms from "./container/pages/Sms/studentSms/FeePaidSms";
import InstiuteStudentCount from "./container/pages/customerSupport/InstiuteStudentCount";
import OFPSInstiuteList from "./container/pages/customerSupport/OFPSInstiuteList";

import OFPSInstiuteListManagement from "./container/pages/customerSupport/OFPSInstiuteListManagement";

import BKASHOFPSInstiuteList from "./container/pages/customerSupport/OfpsBkashList";
import BkashOFPSConfigSave from "./container/pages/customerSupport/BkashOFPSConfigSave";
import BkashOfpsProblemView from "./container/pages/customerSupport/OFPSProblem/BkashOfpsProblem";
import SSLOfpsProblem from "./container/pages/customerSupport/OFPSProblem/SSLOfpsProblem";
import UpayPgwProblemView from "./container/pages/customerSupport/OFPSProblem/UpayPgwProblemView";
import BkashRefundView from "./container/pages/customerSupport/OFPSProblem/BkashRefundView";




import CategoryWiseReport from "./container/pages/Students/reports/CategoryWiseReport";
import PayslipDateUpdate from "./container/pages/PaySlip/PayslipDateUpdate";
import Profile from './container/pages/signup/Profile.page';
import EmptyPage from "./container/pages/sbl/EmptyPage";
import AttendanceDevice from "./container/pages/Students/attendance/AttendanceDevice";
import PartnerInformation from "./container/pages/customerSupport/PartnerInformation";
import MigrationChange from "./container/pages/customerSupport/MigrationChange";
import ShebaSchoolInfo from "./container/pages/customerSupport/ShebaSchoolInfo";
import ZktecoDeviceSettings from "./container/pages/customerSupport/ZktecoDeviceSettings";
import InstiuteListCore from "./container/pages/billing/InstiuteListCore";
import InstiuteListManagement from "./container/pages/billing/InstituteListManagement";
import InstiuteBillListManagement from "./container/pages/billing/InstituteBillingListManagement";
import InstiuteDateToDateBillReportManagement from "./container/pages/billing/InstituteDateToDateBillReportManagement";
import InstiuteDateToDateOfpsReportManagement from "./container/pages/billing/InstituteDateToDateOfpsReportManagement";
import BillingCreate from "./container/pages/billing/BillingCreate";
import BillingUpdate from "./container/pages/billing/BillingUpdate";
import SingleInstitutePayableBill from "./container/pages/billing/SingleInstitutePayableBill";
import SingleInstitutePaidBill from "./container/pages/billing/SingleInstitutePaidBill";
import ManulBillCreate from "./container/pages/billing/ManulBillCreate";
import InstituteCreate from  "./container/pages/masterSetting/InstituteCreate";

import PartnerInfo from "./container/pages/billing/PartnerInfo";
import PartnerInfoSave from "./container/pages/billing/PartnerInfoSave";
import PartnerAssignInstitute from "./container/pages/billing/PartnerAssignInstitute";
import PartnerMapping from "./container/pages/billing/PartnerMapping";
import PartnerSchoolList from "./container/pages/billing/PartnerSchoolList";
import YearlyFeeDetails from "./container/pages/StudentAccounts/report/YearlyFeeDetails";
import ExamReportClassWiseResult from "./container/pages/Exam/reports/ExamReportClassWiseResult";
import GraphicalView from "./container/pages/billing/GraphicalView";
import SeatPlan from "./container/pages/Exam/reports/SeatPlan";

import SanMasikClass from "./container/pages/customerSupport/sanMasik/SanMasikClass";
import SanMasikSubject from "./container/pages/customerSupport/sanMasik/SanMasikSubject";
import SanMasikCompetency from "./container/pages/customerSupport/sanMasik/SanMasikCompetency";
import SanMasikPi from "./container/pages/customerSupport/sanMasik/SanMasikPi";
import SanMasikChapter from "./container/pages/customerSupport/sanMasik/SanMasikChapter";
import SanMasikPiInput from "./container/pages/customerSupport/sanMasik/SanMasikPiInput";

import SanmasikClassInstituteClassConfiguration from "./container/pages/sanmasik/SanmasikClassInstituteClassConfiguration";
import BookWriter from "./container/pages/library/BookWriter";
import BookCoWriter from "./container/pages/library/BookCoWriter";
import SanmasikTranscript from "./container/pages/customerSupport/sanMasik/SanmasikTranscript";
import GenderWiseSummaryReport from "./container/pages/Students/reports/GenderWiseSummaryReport";
import ReligionWiseSummaryReport from "./container/pages/Students/reports/ReligionWiseSummaryReport";
import ClassWiseSummaryReport from "./container/pages/Students/reports/ClassWiseSummaryReport";
import AdmitCardWithRoutine from "./container/pages/Exam/configuration/AdmitCardWithRoutine";
import AdmitCardDownloadCondition from "./container/pages/Exam/configuration/AdmitCardDownloadCondition";

import InventoryCategory from "./container/pages/Inventory/InventoryCategory";
import InventoryItem from "./container/pages/Inventory/InventoryItem";
import InventoryLedgerConfiguration from "./container/pages/Inventory/InventoryLedgerConfiguration";
import InventorySaler from "./container/pages/Inventory/InventorySaler";
import InventoryPurchaseSave from "./container/pages/Inventory/Purchase/InventoryPurchaseSave";
import InventoryPurchaseView from "./container/pages/Inventory/Purchase/InventoryPurchaseView";
import InventorySalesSave from "./container/pages/Inventory/Sales/InventorySalesSave";
import InventorySalesView from "./container/pages/Inventory/Sales/InventorySalesView";
import InventoryItemRecordIn from  "./container/pages/Inventory/InventoryItemRecordIn";
import InventoryItemRecordOut from  "./container/pages/Inventory/InventoryItemRecordOut";
import InventoryItemDestroyRecord from  "./container/pages/Inventory/InventoryItemDestroyRecord";

import PersonWiseBillCollection from "./container/pages/customerSupport/PersonWiseBillCollection";
import PersonWiseInstitute from "./container/pages/customerSupport/PersonWiseInstitute";
import InstituteCountStat from "./container/pages/customerSupport/InstituteCountStat";
import InstituteTransactionStatus from "./container/pages/customerSupport/InstituteTransactionStatus";
import InstituteCreateList from "./container/pages/customerSupport/InstituteCreateList";

import HeadAddition from "./container/pages/payroll/Settings/HeadAddition.page";
import HeadDeduction from "./container/pages/payroll/Settings/HeadDeduction.page";
import SalaryGrade from "./container/pages/payroll/Settings/SalaryGrade.page";
import BankAdviseContent from "./container/pages/payroll/Settings/BankAdviseContent";
import EmployeeSalary from "./container/pages/payroll/configuarion/EmployeeSalary.page";
import EmployeeBankAccount from "./container/pages/payroll/Settings/EmployeeBankAccount";
import SalaryDefaultConfig from "./container/pages/payroll/Settings/SalaryDefaultConfig";
import SalarySheetCreate from "./container/pages/payroll/salary/SalarySheetCreate";
import SalaryPayment from "./container/pages/payroll/salary/SalaryPayment";




const { Header, Content, Sider } = Layout;
// Application
export default function Container() {
  const user = useStoreState((state) => state.auth.user);
  const [collapsed, setCollapsed] = useState(false);
  const toggle = () => setCollapsed(!collapsed);
  var isMobile = false; //initiate as false
  if (
    /iP(hone|od)|android.+mobile|BlackBerry|IEMobile/i.test(navigator.userAgent)
  ) {
    isMobile = true;
  }
  const onCollapse = (collapsed) => {
    //console.log("hello")
    //console.log(collapsed);
    setCollapsed(collapsed);
  };
  const fetchInstituteInfo = useStoreActions((state) => state.auth.fetchInstituteInfo);
  useEffect(() => {
    fetchInstituteInfo(1);
  }, []);
  const d = new Date();
  let year = d.getFullYear();
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        id="mySider"
        collapsible
        collapsed={collapsed}
        onCollapse={onCollapse}
        breakpoint="lg"
        onBreakpoint={(broken) => {
          // console.log(broken);
        }}
        trigger={null}
        collapsedWidth={isMobile ? 0 : 80}
        width="230"
        style={{
          height: "100vh",
          overflow: "auto",
          position: isMobile ? "fixed" : "sticky",
          left: 0,
          top: 0,
          zIndex: 1000,
        }}
      >
        <div className="logo">
          {!collapsed && (
            <>
              <Link to={ROUTES.DEFAULT} className="nav-text"><img src={logo} /></Link>
            </>
          )}
          {collapsed && (
            <div style={{ marginLeft: 15 }}>
              <AppstoreOutlined />
            </div>
          )}
          {/* {!collapsed && <span style={{color:"white", fontSize:12}}> {user?.instituteName}</span>} */}
        </div>
        {user && (
          <div className="sidebar-wrapper" id="style-4">
            {" "}
            <Sidebar />
          </div>
        )}
      </Sider>
      <Layout className="site-layout">
        <Header className="site-layout-background" style={{ position: 'fixed', zIndex: 1 }} id="topFixedBar">
          {/* <Header className="site-layout-background"> */}
          <TopNavBar value={{ collapsed: collapsed, toggle: toggle }} />
        </Header>
        <Content className="p-15" style={{ marginTop: "64px" }}>
          {/* <Content className="p-15"> */}
          <Switch>
            <Route exact path="/" component={Dashboard} />
            //////////***************** Students *************//////////////////////


            <Route exact path={'/' + ROUTES.STUDENT_WRAPPER} component={StudentWrapper} />
            <Route exact path={'/' + ROUTES.STUDENT_CLASS_CONFIG} component={CalssConfiguration} />
            <Route exact path={'/' + ROUTES.CLASS_SEMESTER_CONFIG} component={GroupConfiguration} />
            <Route exact path={'/' + ROUTES.GROUP_CONFIG_CUSTOMER_SUPPORT} component={GroupConfigurationCustomerSupport} />
            <Route exact path={'/' + ROUTES.STUDENT_MULTIPLE_REGISTRATION} component={MultipleRegistrationWrapper} />
            <Route exact path={'/' + ROUTES.STUDENT_MULTIPLE_REGISTRATION_EXCEL} component={MultipleRegistrationExeclWrapper} />
            <Route exact path={'/' + ROUTES.STUDENT_BASIC_UPDATE} component={BasicUpdate} />
            <Route exact path={'/' + ROUTES.STUDENT_BASIC_UPDATE_INTERNATIONAL} component={BasicUpdateInternational} />
            <Route exact path={'/' + ROUTES.STUDENT_PROFILE_UPDATE} component={StudentProfileUpdate} />
            <Route exact path={'/' + ROUTES.STUDENT_MIGRATION} component={StudentMigration} />
            <Route exact path={'/' + ROUTES.STUDENT_PHOTO_UPDATE} component={PhotoUpdate} />
            <Route exact path={'/' + ROUTES.STUDENT_ACADEMICINFO_UPDATE} component={AcademicInfo} />
            <Route exact path={'/' + ROUTES.STUDENT_ADDRESS_UPDATE} component={AddressInfoUpdate} />
            <Route exact path={'/' + ROUTES.STUDENT_REGISTRATION_DELETE} component={StudentDelete} />
            <Route exact path={'/' + ROUTES.STUDENT_ID_CARD_INFORMATION} component={IdCardStudentInfo} />
            <Route exact path={'/' + ROUTES.STUDENT_ID_CARD_TEMPLATE} component={IdCardTemplate} />
            <Route exact path={'/' + ROUTES.STUDENT_ID_CARD_TEMPLATE_DOWNLOAD} component={IdCardTemplateDownload} />
            <Route exact path={'/' + ROUTES.CLASS_WISE_REPORT} component={ClassWiseReport} />
            <Route exact path={'/' + ROUTES.SECTION_WISE_STUDENT_REPORT} component={SectionWiseReport} />
            <Route exact path={'/' + ROUTES.SECTION_WISE_SUMMARY_REPORT_INTERNATIONAL} component={SectionWiseReportInternational} />
            <Route exact path={'/' + ROUTES.SUBJECT_WISE_SUMMARY_REPORT} component={SubjectWiseReport} />
            <Route exact path={'/' + ROUTES.GROUP_WISE_STUDENT_REPORT} component={GroupWiseReport} />
            <Route exact path={'/' + ROUTES.TAUGHT_LIST_STUDENT_REPORT} component={TaughtListReport} />
            <Route exact path={'/' + ROUTES.SECTION_WISE_SUMMARY_REPORT} component={SectionWiseSummaryReport} />
            <Route exact path={'/' + ROUTES.GENDER_WISE_SUMMARY_REPORT} component={GenderWiseSummaryReport} />
            <Route exact path={'/' + ROUTES.RELIGION_WISE_SUMMARY_REPORT} component={ReligionWiseSummaryReport} />
            <Route exact path={'/' + ROUTES.GENDER_WISE_SUMMARY_REPORT} component={ReligionWiseSummaryReport} />

            <Route exact path={'/' + ROUTES.CLASS_WISE_SUMMARY_REPORT} component={ClassWiseSummaryReport} />


            <Route exact path={'/' + ROUTES.SECTION_OVERVIEW_REPORT} component={StudentOverViewReportReport} />
            <Route exact path={'/' + ROUTES.SECTION_PROFILE_REPORT} component={StudentProfileReport} />
            <Route exact path={'/' + ROUTES.MONEY_RECIPT_TEMPLATE} component={MoneyReciptTemplate} />
            <Route exact path={'/' + ROUTES.INITIAL_SETUP_PERIOD} component={TakePeriod} />
            <Route exact path={'/' + ROUTES.STUDENT_ATTENDANCE_PERIOD_TIME_CONFIGURATION} component={PeriodTimeConfiguration} />
            <Route exact path={'/' + ROUTES.STUDENT_ATTENDANCE_DEVICE} component={AttendanceDevice} />
            <Route exact path={'/' + ROUTES.STUDENT_DEVICE_ID_MAPPING} component={DeviceIdMapping} />
            <Route exact path={'/' + ROUTES.STUDENT_ATTENDANCE_INPUT} component={TakeAttendance} />
            <Route exact path={'/' + ROUTES.STUDENT_ATTENDANCE_UPDATE} component={TakeAttendanceUpdate} />
            <Route exact path={'/' + ROUTES.STUDENT_ATTENDANCE_EXAM} component={ExamAttendance} />
            <Route exact path={'/' + ROUTES.STUDENT_LEAVE_INPUT} component={LeaveInput} />
            <Route exact path={'/' + ROUTES.DAILY_ATTENSION_REPORT_DAILY_SECTION} component={DailySectionReport} />
            <Route exact path={'/' + ROUTES.DAILY_ATTENSION_REPORT_SECTION_WISE_DAILY_SUMMARY} component={SectionWiseDailySummary} />
            <Route exact path={'/' + ROUTES.STUDENT_ATTENDANCE_REPORT_MONTHLY_SECTION_DETAILS} component={SectionWiseMonthlyDetailsSummary} />
            <Route exact path={'/' + ROUTES.STUDENT_ATTENDANCE_REPORT_MONTHLY_SECTION_SUMMARY} component={SectionWiseMonthlyDetails} />
            <Route exact path={'/' + ROUTES.STUDENT_ATTENDANCE_SECTION_SUMMARY} component={SectionWiseSummaryAttendance} />
            <Route exact path={'/' + ROUTES.STUDENT_ATTENDANCE_MACHINE_INPUT} component={StudentFileUpload} />
            <Route exact path={'/' + ROUTES.STUDENT_SINGLE_SEARCH} component={SingleSearchStudent} />
            <Route exact path={'/' + ROUTES.STUDENT_UPDATE_ID} component={StudentIDUpdate} />
            <Route exact path={'/' + ROUTES.STUDENT_UPDATE_ROLL} component={StudentRollUpdateExcel} />
            <Route exact path={'/' + ROUTES.STUDENT_ENABLED_DISABLED} component={StudentEnableDisable} />
            <Route exact path={'/' + ROUTES.GENERAL_STUDENT_MIGRATION} component={StudentMigrationGeneral} />
            <Route exact path={'/' + ROUTES.EXCEL_STUDENT_MIGRATION} component={StudentMigrationExcel} />
            <Route exact path={'/' + ROUTES.MERIT_STUDENT_MIGRATION} component={StudentMigrationMerit} />
            <Route exact path={'/' + ROUTES.STUDENT_REPORT_BASIC_INFO_CATEGORY_WISE_REPORT} component={CategoryWiseReport} />
            //////////***************** Students *************//////////////////////


            //////////***************** Staff *************//////////////////////
            <Route exact path={'/' + ROUTES.STAFF_SETTINGS} component={StaffSettings} />
            <Route exact path={'/' + ROUTES.STAFF_SETTINGS} component={StaffSettings} />
            <Route exact path={'/' + ROUTES.STAFF_SINGLE_REGISTRATION} component={SingleRegistration} />
            <Route exact path={'/' + ROUTES.STAFF_MULTIPLE_REGISTRATION} component={MultipleRegistrationStaff} />
            <Route exact path={'/' + ROUTES.STAFF_UPDATE} component={StaffUpdate} />
            <Route exact path={'/' + ROUTES.STAFF_UPDATE_SINGLE} component={StaffUpdateSingle} />
            <Route exact path={'/' + ROUTES.STAFF_ASSIGN} component={StaffAssign} />
            <Route exact path={'/' + ROUTES.SUBJECT_TEACHER_ASSIGN} component={StaffSubjectAssignWrapper} />
            <Route exact path={'/' + ROUTES.STAFF_LIST} component={StaffList} />
            <Route exact path={'/' + ROUTES.STAFF_ID_TEMP} component={StaffIdCardTemplate} />
            //////////***************** Staff *************//////////////////////

            //////////***************** Staff Attedance *************//////////////////////
            <Route exact path={'/' + ROUTES.HR_ATTENDANCE_TAKE} component={TakeAttendanceSave} />
            <Route exact path={'/' + ROUTES.HR_ATTENDANCE_UPDATE} component={UpdateAttendance} />
            <Route exact path={'/' + ROUTES.HR_SINGLE_LEAVE} component={TakeSingleLeave} />
            <Route exact path={'/' + ROUTES.HR_TIME_CONFIG} component={StaffTimeConfig} />
            <Route exact path={'/' + ROUTES.HR_TD_MAPPING} component={StaffIdMappingWrapper} />
            <Route exact path={'/' + ROUTES.HR_REPORT_TIME_CONFIG} component={StaffAttendanceTimeConfigReport} />
            <Route exact path={'/' + ROUTES.HR_REPORT_DATE_WISE} component={StaffAttendanceDateWiseReport} />
            <Route exact path={'/' + ROUTES.HR_REPORT_MONTH_WISE} component={StaffAttendanceMonthWiseReport} />
            <Route exact path={'/' + ROUTES.HR_REMARK_DATE_WISE} component={StaffAttendanceDateWiseRemark} />
            <Route exact path={'/' + ROUTES.HOLIDAY} component={HolidayWrapper} />
            <Route exact path={'/' + ROUTES.HR_FILE_UPLOAD} component={StaffFileUpload} />
            <Route exact path={'/' + ROUTES.STAFF_ATTENDANCE_MACHINE} component={StaffAttendanceMachine} />
            //////////***************** Staff Attedance *************//////////////////////
            
            //////////***************** General Accounts *************//////////////////////
            <Route exact path={'/' + ROUTES.LEDGER_LIST} component={LedgerList} />
            <Route exact path={'/' + ROUTES.RECEIVE_TRN_TRANSACTION} component={Receive} />
            <Route exact path={'/' + ROUTES.PAYMENT_TRN_TRANSACTION} component={Payment} />
            <Route exact path={'/' + ROUTES.CONTRA_TRN_TRANSACTION} component={Contra} />
            <Route exact path={'/' + ROUTES.JOURNAL_TRN_TRANSACTION} component={Journal} />
            <Route exact path={'/' + ROUTES.DELETE_VOUCHER_GAVOUCHER} component={Voucher} />
            <Route exact path={'/' + ROUTES.TRIAL_BALANCE_GAREPORT} component={TrialBalance} />
            <Route exact path={'/' + ROUTES.BALANCE_SHEET_GAREPORT} component={BalanceSheet} />
            <Route exact path={'/' + ROUTES.INCOME_STATEMENT_GAREPORT} component={IncomeStatement} />
            <Route exact path={'/' + ROUTES.JOURNAL_GAREPORT} component={JournalR} />
            <Route exact path={'/' + ROUTES.FUNDS_FLOW_GAREPORT} component={FundsFlow} />
            <Route exact path={'/' + ROUTES.CASH_SUMMARY_GAREPORT} component={CashSummary} />
            <Route exact path={'/' + ROUTES.USER_WISE_TRAN} component={UserWiseTransaction} />
            <Route exact path={'/' + ROUTES.VOUCHER_TEMPLATE} component={VoucherTemplate} />
            <Route exact path={'/' + ROUTES.ACCOUNT_BOOK} component={AccountsBookTab} />
            //////////***************** General Accounts *************//////////////////////

            //////////***************** Student Accounts *************//////////////////////
            <Route exact path={'/' + ROUTES.FEE_HEAD} component={FeeHead} />
            <Route exact path={'/' + ROUTES.FEE_SUB_HEAD} component={FeeSubHead} />
            <Route exact path={'/' + ROUTES.FEE_HEAD_WAIVER} component={FeeWaiver} />
            <Route exact path={'/' + ROUTES.FEE_HEAD_LEDGER_CONFIGUARTION} component={FeeHeadLedger} />
            <Route exact path={'/' + ROUTES.FEE_SUB_HEAD_LEDGER_CONFIGUARTION} component={FeeSubHeadLedger} />
            <Route exact path={'/' + ROUTES.OFPS_ACCOUNT_CONFIG} component={OFPS} />
            <Route exact path={'/' + ROUTES.FEE_FINE_LEDGER_CONFIGUARTION} component={FeeFineLedger} />
            <Route exact path={'/' + ROUTES.FEE_AMOUNT_CONFIGUARTION} component={FeeAmountConfig} />
            <Route exact path={'/' + ROUTES.FEE_SUBHEAD_TIME_CONFIGUARTION} component={FeeSubHeadTimeConfig} />
            <Route exact path={'/' + ROUTES.FEE_WAIVE_CONFIGUARTION} component={FeeWaiverConfig} />
            <Route exact path={'/' + ROUTES.FEE_HEAD_DELETE_CONFIGUARTION} component={FeeHeadDelete} />
            <Route exact path={'/' + ROUTES.FEE_COLLECTION_MANUAL} component={FeesCollection} />
            <Route exact path={'/' + ROUTES.FEE_REPORT_SECTION_PAID_LIST} component={SectionPaidInfo} />
            <Route exact path={'/' + ROUTES.FEE_REPORT_CLASS_PAID_LIST} component={ClassPaidInfo} />
            <Route exact path={'/' + ROUTES.FEE_REPORT_ALL_PAID_LIST} component={AllPaidInfo} />
            <Route exact path={'/' + ROUTES.FEE_REPORT_SECTION_DUE} component={SectionDueDetails} />
            <Route exact path={'/' + ROUTES.FEE_SUBHEAD_COLLECTION_REPORT} component={FeeSubHeadCollectionDetails} />
            <Route exact path={'/' + ROUTES.FEE_REPORT_MONTHLY_DUE} component={MonthlyDueDetails} />
            <Route exact path={'/' + ROUTES.FEE_REPORT_FEE_COLLECTION} component={FeeHeadCollection} />
            <Route exact path={'/' + ROUTES.FEE_REPORT_FEE_COLLECTION_CLASS} component={FeeHeadCollectionClass} />
            <Route exact path={'/' + ROUTES.FEE_HEAD_COLLECTION_DATE_TO_DATE_ALL_SECTION} component={FeeHeadCollectionDateToDateAllSecion} />
            <Route exact path={'/' + ROUTES.STUDENT_GIVEN_WAIVER} component={StudentGivenWaiver} />
            <Route exact path={'/' + ROUTES.SPG_STATEMENT} component={SpgStatement} />
            <Route exact path={'/' + ROUTES.SPG_SUMMARY} component={SpgSummary} />            
            <Route exact path={'/' + ROUTES.BKASH_STATEMENT} component={BkashStatement} />
            <Route exact path={'/' + ROUTES.BKASH_SUMMARY} component={BkashSummary} />
            <Route exact path={'/' + ROUTES.YEARLY_FEE_DETAILS} component={YearlyFeeDetails} />
            <Route exact path={'/' + ROUTES.FEE_DELETE_INVOICE} component={Invoice} />
            <Route exact path={'/' + ROUTES.FEE_DISCARD} component={FeeDiscardWrapper} />
            <Route exact path={'/' + ROUTES.FEE_AMOUNT_VIEW} component={FeeAmounView} />
            <Route exact path={'/' + ROUTES.FEE_SUBHEAD_DISCARD} component={FeeSubHeadDiscardWrapper} />
            <Route exact path={'/' + ROUTES.ATTENDANCE_FINE} component={AttendanceFineWrapper} />
            <Route exact path={'/' + ROUTES.INDIVIDUAL_FEE_ASSIGN} component={IndividualFeeAssignWrapper} />
            <Route exact path={'/' + ROUTES.UPAY_PGW_STATEMENT} component={UpayPgwStatement} />
            <Route exact path={'/' + ROUTES.UPAY_PGW_SUMMARY} component={UpayPgwSummary} />
            <Route exact path={'/' + ROUTES.UPAY_PAY_BILL} component={UpayPayBillProblem} />
            <Route exact path={'/' + ROUTES.PREMIER_BANK_SSL_STATEMENT} component={PremierBankSslStatement} />

           //////////***************** Student Accounts *************//////////////////////

            //////////***************** Student Exam *************//////////////////////
            <Route exact path={'/' + ROUTES.EXAM_SETTING} component={ExamSettingsWrapper} />
            <Route exact path={'/' + ROUTES.EXAM_SUBJECT_CONFIGURATION} component={ExamSubject} />
            <Route exact path={'/' + ROUTES.EXAM_CONFIGURATION} component={ExamConfiguration} />
            <Route exact path={'/' + ROUTES.EXAM_BLANK_SHEET} component={ExamReportBlankSheet} />
            <Route exact path={'/' + ROUTES.EXAM_ROUTINE2} component={ExamRoutineWrapper} />
            <Route exact path={'/' + ROUTES.EXAM_MARK_CONFIGURE} component={MarkConfigPage} />
            <Route exact path={'/' + ROUTES.EXAM_GRANDFINAL_MARK_CONFIGURE} component={GrandFinalMark} />
            <Route exact path={'/' + ROUTES.EXAM_FOUR_SUBJECT_ASSIGN} component={ExamFortSubject} />
            <Route exact path={'/' + ROUTES.ADMIT_CARD_INSTRUCTION} component={AdmitCardInstruction} />
            <Route exact path={'/' + ROUTES.EXAM_TEMPLATE} component={ExamTemplate} />
            <Route exact path={'/' + ROUTES.ADMIT_CARD_SEAT_PLAN} component={AdmitCardSeatPlanWrapper} />

            <Route exact path={'/' + ROUTES.ADMIT_CARD_WITH_ROUTINE} component={AdmitCardWithRoutine} />
            <Route exact path={'/' + ROUTES.ADMIT_CARD_DOWNLOAD_CONDITION} component={AdmitCardDownloadCondition} />


            <Route exact path={'/' + ROUTES.EXAM_MARK_INPUT} component={MarkInputForm} />
            <Route exact path={'/' + ROUTES.EXAM_MARK_UPDATE} component={MarkUpdateForm} />               
            <Route exact path={'/' + ROUTES.EXAM_MARK_INPUT_SPECIAL} component={MarkInputSpecial} />
            <Route exact path={'/' + ROUTES.EXAM_MARK_UPDATE_SPECIAL} component={MarkUpdateSpecial} />            
            <Route exact path={'/' + ROUTES.EXAM_MARK_SHEET_SPECIAL} component={MarkSheetSpecial} />            
            <Route exact path={'/' + ROUTES.EXAM_MARK_INPUT_SUBJECT} component={MarkInputFormSubject} />
            <Route exact path={'/' + ROUTES.EXAM_MARK_UPDATE_SUBJECT} component={MarkUpdateFormSubject} />
            <Route exact path={'/' + ROUTES.EXAM_MARK_PROBLEM} component={MarkProblemDelete} />
            <Route exact path={'/' + ROUTES.EXAM_MARK_PROCESS_CLASS} component={MarkProcessClass} />
            <Route exaxt path={'/' + ROUTES.EXAM_MARK_DELETE} component={MarkDelete}></Route>
            <Route exaxt path={'/' + ROUTES.EXAM_GENERAL_RESULT_PROCESS} component={GeneralExam}></Route>
            <Route exaxt path={'/' + ROUTES.EXAM_GENERAL_RESULT_PROCESS_GRAND_FINAL} component={GrandFinal}></Route>
            <Route exaxt path={'/' + ROUTES.EXAM_GENERAL_RESULT_MERIT_POSITION} component={MeritPostion}></Route>
            <Route exaxt path={'/' + ROUTES.EXAM_REMARKS_GIVE} component={ExamRemarks}></Route>
            <Route exaxt path={'/' + ROUTES.EXAM_GENERAL_TEBULATION_SHEET} component={TabulationSheet}></Route>
            <Route exaxt path={'/' + ROUTES.EXAM_GENERAL_MARKSHEET_DOWNLOAD} component={GeneralMarkSheetDownload}></Route>
            <Route exaxt path={'/' + ROUTES.EXAM_GRAND_MARKSHEET_DOWNLOAD} component={GrandFinalMarkSheetDownload}></Route>
            <Route exaxt path={'/' + ROUTES.EXAM_REPORT_SECTION_WISE_UNINPUTTED_SUBJECTS} component={ExamReportSectionWiseUninputtedSubjects}></Route>
            <Route exaxt path={'/' + ROUTES.EXAM_REPORT_SECTION_RESULT} component={ExamReportSectionWiseResult}></Route>
            <Route exaxt path={'/' + ROUTES.EXAM_REPORT_CLASS_RESULT} component={ExamReportClassWiseResult}></Route>
            <Route exaxt path={'/' + ROUTES.EXAM_REPORT_SECTION_WISE_MERIT_LIST} component={SectionWiseMeritList}></Route>
            <Route exaxt path={'/' + ROUTES.EXAM_REPORT_CLASS_WISE_MERIT_LIST} component={ClassWiseMeritList}></Route>
            <Route exaxt path={'/' + ROUTES.EXAM_REPORT_GROUP_WISE_MERIT_LIST} component={GroupWiseMeritList}></Route>
            <Route exaxt path={'/' + ROUTES.EXAM_REPORT_CLASS_FAILED} component={ClassWiseFailedList}></Route>
            <Route exaxt path={'/' + ROUTES.EXAM_REPORT_SECTION_FAILED} component={SectionWiseFailedList}></Route>
            <Route exaxt path={'/' + ROUTES.EXAM_REPORT_SECTION_WISE_GRADING_SUMMARY} component={SectionWiseGradingSummary}></Route>
            <Route exaxt path={'/' + ROUTES.EXAM_REPORT_SUBJECT_WISE_GRADING_SUMMARY} component={ExamReportSubjectGrading}></Route>
            <Route exaxt path={'/' + ROUTES.EXAM_REPORT_SECTION_WISE_PASS_FAIL_SUMMARY} component={SectionWisePassFailSummary}></Route>
            <Route exaxt path={'/' + ROUTES.EXAM_RESULT_PUBLISH} component={ResultPublicationWrapper}></Route>
           //////////***************** Student Exam *************//////////////////////
            //////////***************** ATTENDANCE *************//////////////////////
            //////////***************** Payslip *************//////////////////////
            <Route exaxt path={'/' + ROUTES.PAYSLIP_CREATE_SINGLE} component={PaySlipCreateSingle}></Route>
            <Route exaxt path={'/' + ROUTES.PAYSLIP_CREATE_BATCH} component={CreatePaySlipBatch}></Route>
            <Route exaxt path={'/' + ROUTES.PAYSLIP_COLLECT} component={CollectPaySlip}></Route>
            <Route exaxt path={'/' + ROUTES.PAYSLIP_TEMPLATE} component={PayslipTemplate}></Route>
            <Route exaxt path={'/' + ROUTES.PAYSLIP_DATE_UPDATE} component={PayslipDateUpdate}></Route>
           //////////***************** Payslip *************//////////////////////
            //////////***************** SMS *************//////////////////////
            <Route exaxt path={'/' + ROUTES.SMS_SEND_DYNAMIC} component={DynamicSms}></Route>
            <Route exaxt path={'/' + ROUTES.STAFF_SMS} component={StaffSms}></Route>
            <Route exaxt path={'/' + ROUTES.STUDENT_CLASSWISE_SMS} component={ClassWiseSms}></Route>
            <Route exaxt path={'/' + ROUTES.STUDENT_SECTIONWISE_SMS} component={SectionWiseSms}></Route>
            <Route exaxt path={'/' + ROUTES.SEND_SMS_EXAM_RESULT} component={ExamResultSms}></Route>
            <Route exaxt path={'/' + ROUTES.STUDENT_INSTIUTE_SMS} component={InstiuteSms}></Route>
            <Route exaxt path={'/' + ROUTES.SECTION_DUE_SMS} component={SectionDueSms}></Route>
            <Route exaxt path={'/' + ROUTES.SMS_TEMPLATE} component={SmsTemplates}></Route>
            <Route exaxt path={'/' + ROUTES.GLOBAL_SMS_TEMPLATE} component={GlobalSmsTemplate}></Route>
            <Route exaxt path={'/' + ROUTES.SMS_PURCHASE} component={PurchaseSms}></Route>
            <Route exaxt path={'/' + ROUTES.GENERAL_SMS_LIST} component={GeneralMessageList}></Route>
            <Route exaxt path={'/' + ROUTES.STAFF_SMS_LIST} component={StaffMessageList}></Route>
            <Route exaxt path={'/' + ROUTES.STUDENT_SMS_LIST} component={StudentMessageList}></Route>
            <Route exaxt path={'/' + ROUTES.SMS_BALANCE} component={SmsBalance}></Route>
            <Route exaxt path={'/' + ROUTES.FEE_PAID_SMS} component={FeePaidSms} ></Route>
            <Route exaxt path={'/' + ROUTES.SMS_PURCHASE_LOG} component={SmsPurchaseLog} ></Route>
            <Route exaxt path={'/' + ROUTES.REGISTRATION_SMS} component={RegistrationSms} ></Route>
            
           //////////***************** SMS *************//////////////////////
            /////////*********Master Setting*******////////////
            <Route exaxt path={'/' + ROUTES.INSTITUTE_UPDATE} component={InstituteUpdate}></Route>
            <Route exaxt path={'/' + ROUTES.DEFAULT_SIGNATURE} component={DefaultSignature}></Route>
            <Route exaxt path={'/' + ROUTES.MASTER_USER_LIST} component={UsersList}></Route>
            <Route exaxt path={'/' + ROUTES.MASTER_PAYABLE_BILL_LIST} component={PayableBill}></Route>
            <Route exaxt path={'/' + ROUTES.MASTER_PAID_BILL_LIST} component={PaidBill}></Route>
            <Route exaxt path={'/' + ROUTES.INSTITUTE_CREATE} component={InstituteCreate}></Route>

           /////////*********TC/Testimonial Setting*******////////////
            <Route exaxt path={'/' + ROUTES.TESTIMONIALS} component={Tetimonial}></Route>
            <Route exaxt path={'/' + ROUTES.TRANSFER_CERTIFICATE} component={TransferCertificate}></Route>
           /////////*********TC/Testimonial Setting*******////////////

           //////////***************** Payroll *************//////////////////////
            <Route exact path={'/' +ROUTES.SETTINGS_PAYROLL_ADDITION} component={HeadAddition} />
            <Route exact path={'/' +ROUTES.SETTINGS_PAYROLL_DEDUCTION} component={HeadDeduction} />
            <Route exact path={'/' +ROUTES.SETTINGS_PAYROLL_GRADE} component={SalaryGrade} />
            <Route exact path={'/' +ROUTES.SALARY_PAYMENT} component={SalaryPayment} />
            <Route exact path={'/' +ROUTES.BANK_ADVISE_CONTENT} component={BankAdviseContent} />
            <Route exact path={'/' +ROUTES.EMPLOYEE_SALARY_CONFIGUARION} component={EmployeeSalary} />
            <Route exact path={'/' +ROUTES.EMPLOYEE_BANK_ACCOUNT} component={EmployeeBankAccount} />
            <Route exact path={'/' +ROUTES.SALARY_DEFAULT_CONFIG} component={SalaryDefaultConfig} />
            <Route exact path={'/' +ROUTES.SALARY_SHEET_CREATE} component={SalarySheetCreate} />
            //////////***************** Payroll *************//////////////////////
            
           /////////*********Library*******////////////
            <Route exaxt path={'/' + ROUTES.BOOKCATEGORY} component={BookCategory}></Route>
            <Route exaxt path={'/' + ROUTES.ADDWRITER} component={BookWriter}></Route>
            <Route exaxt path={'/' + ROUTES.ADDCOWRITER} component={BookCoWriter}></Route>
           /////////*********TC/Testimonial Setting*******////////////

           /////////*********Inventory*******////////////
            <Route exaxt path={'/' + ROUTES.INVENTORY_CATEGORY} component={InventoryCategory}></Route>
            <Route exaxt path={'/' + ROUTES.INVENTORY_ITEM} component={InventoryItem}></Route>
            <Route exaxt path={'/' + ROUTES.INVENTORY_SALER} component={InventorySaler}></Route>
            <Route exaxt path={'/' + ROUTES.INVENTORY_LEDGER_CONFIGURATION} component={InventoryLedgerConfiguration}></Route>
            <Route exaxt path={'/' + ROUTES.INVENTORY_PURCHASE_SAVE} component={InventoryPurchaseSave}></Route>
            <Route exaxt path={'/' + ROUTES.INVENTORY_PURCHASE_VIEW} component={InventoryPurchaseView}></Route>
            <Route exaxt path={'/' + ROUTES.INVENTORY_SALES_SAVE} component={InventorySalesSave}></Route>
            <Route exaxt path={'/' + ROUTES.INVENTORY_SALES_VIEW} component={InventorySalesView}></Route>
            <Route exaxt path={'/' + ROUTES.INVENTORY_ITEM_RECORD_IN} component={InventoryItemRecordIn}></Route>
            <Route exaxt path={'/' + ROUTES.INVENTORY_ITEM_RECORD_OUT} component={InventoryItemRecordOut}></Route>
            <Route exaxt path={'/' + ROUTES.INVENTORY_ITEM_DESTROY_RECORD} component={InventoryItemDestroyRecord}></Route>


            /////////*********Customer Support Setting*******////////////
            <Route exaxt path={'/' + ROUTES.INSTITUTE_LIST} component={InstiuteList}></Route>
            <Route exaxt path={'/' + ROUTES.INSTITUTE_LIST_CORE} component={InstiuteListCore}></Route>
            
            <Route exaxt path={'/' + ROUTES.BKASH_OFPS_INSTITUTE_LIST} component={BKASHOFPSInstiuteList}></Route>
            <Route exaxt path={'/' + ROUTES.OFPS_INSTITUTE_LIST} component={OFPSInstiuteList}></Route>
            <Route exaxt path={'/' + ROUTES.INSTITUTE_STUDENT_COUNT} component={InstiuteStudentCount}></Route>
            <Route exaxt path={'/' + ROUTES.INSTITUTE_JUMP} component={InstituteJump}></Route>
            <Route exaxt path={'/' + ROUTES.EXAM_GRADE_UPDATE} component={ExamGradeUpdate}></Route>
            <Route exaxt path={'/' + ROUTES.MIGRATION_CHANGE} component={MigrationChange}></Route>
            <Route exaxt path={'/' + ROUTES.SHEBA_SCHOOL_INFO} component={ShebaSchoolInfo}></Route>
            <Route exaxt path={'/' + ROUTES.ZKTECO_DEVICE_SETTINGS} component={ZktecoDeviceSettings}></Route>

            
           /////////*********Customer Support Setting*******////////////
          
           /////////*********Customer Support Sanmasik*******////////////
           <Route exaxt path={'/' + ROUTES.SANMASIK_CLASS} component={SanMasikClass}></Route>
           <Route exaxt path={'/' + ROUTES.SANMASIK_SUBJECT} component={SanMasikSubject}></Route>
           <Route exaxt path={'/' + ROUTES.SANMASIK_COMPETENCY} component={SanMasikCompetency}></Route>
           <Route exaxt path={'/' + ROUTES.SANMASIK_PI} component={SanMasikPi}></Route>
           <Route exaxt path={'/' + ROUTES.SANMASIK_CHAPTER} component={SanMasikChapter}></Route>
           <Route exaxt path={'/' + ROUTES.SANMASIK_PI_INPUT} component={SanMasikPiInput}></Route>
           <Route exaxt path={'/' + ROUTES.SANMASIK_TRANSCRIPT_DOWNLOAD} component={SanmasikTranscript}></Route>

           <Route exaxt path={'/' + ROUTES.SANMASIK_CLASS_INSTITUTE_CLASS_CONFIGURATION} component={SanmasikClassInstituteClassConfiguration}></Route>
           /////////*********Customer Support Sanmasik*******////////////

           /////////********* Management View *******////////////
           <Route exaxt path={'/' + ROUTES.INSTITUTE_LIST_MANAGEMENT} component={InstiuteListManagement}></Route>
           <Route exaxt path={'/' + ROUTES.OFPS_INSTITUTE_LIST_MANAGEMENT} component={OFPSInstiuteListManagement}></Route>
           <Route exaxt path={'/' + ROUTES.BKASH_OFPS_CONFIGURATION_SAVE} component={BkashOFPSConfigSave}></Route>
           <Route exaxt path={'/' + ROUTES.INSTITUTE_BILL_LIST_MANAGEMENT} component={InstiuteBillListManagement}></Route>
           <Route exaxt path={'/' + ROUTES.INSTITUTE_DATE_TO_DATE_BILL_REPORT_MANAGEMENT} component={InstiuteDateToDateBillReportManagement}></Route>
           <Route exaxt path={'/' + ROUTES.INSTITUTE_DATE_TO_DATE_OFPS_REPORT_MANAGEMENT} component={InstiuteDateToDateOfpsReportManagement}></Route>
           
           
           /////////********* Management View *******////////////
           
           /////////*********Billing*******////////////
           <Route exaxt path={'/' + ROUTES.BILL_CREATE} component={BillingCreate}></Route>
           <Route exaxt path={'/' + ROUTES.BILL_UPDATE} component={BillingUpdate}></Route>
           <Route exaxt path={'/' + ROUTES.BILL_CREATE_MANUAL} component={ManulBillCreate}></Route>
           <Route exaxt path={'/' + ROUTES.SINGLE_INSTITUTE_PAYABLE_BILL} component={SingleInstitutePayableBill}></Route>
           <Route exaxt path={'/' + ROUTES.SINGLE_INSTITUTE_PAID_BILL} component={SingleInstitutePaidBill}></Route>

           <Route exaxt path={'/' + ROUTES.PARTNER_INFO} component={PartnerInfo}></Route>
           <Route exaxt path={'/' + ROUTES.PARTNER_INFO_SAVE} component={PartnerInfoSave}></Route>
           <Route exaxt path={'/' + ROUTES.PARTNER_ASSIGN_INSTITUTE} component={PartnerAssignInstitute}></Route>
           <Route exaxt path={'/' + ROUTES.PARTNER_MAPPING} component={PartnerMapping}></Route>
           <Route exaxt path={'/' + ROUTES.PARTNER_SCHOOL_LIST} component={PartnerSchoolList}></Route>
           /////////*********Billing*******////////////

            <Route exact path={'/' + ROUTES.USER_LIST} component={Users} />
            /////////********* /*******Miscellaneous*/*******////////////
            <Route exact path={'/' + ROUTES.CLASS_ROUTINE} component={ClassRoutine} />
            <Route exact path={'/' + ROUTES.EXAM_ROUTINE} component={ExamRoutine} />
            <Route exact path={'/' + ROUTES.OFPS_PROBLEM} component={OfpsProblemView} />
            <Route exact path={'/' + ROUTES.BKASH_OFPS_PROBLEM} component={BkashOfpsProblemView} />
            <Route exact path={'/' + ROUTES.SSL_OFPS_PROBLEM} component={SSLOfpsProblem} />
            <Route exact path={'/' + ROUTES.UPAY_PGW_OFPS_PROBLEM} component={UpayPgwProblemView} />
            <Route exact path={'/' + ROUTES.BKASH_REFUND_VIEW} component={BkashRefundView} />
            
            
            /////////*********Online Admission*******////////////
            <Route exact path={'/' + ROUTES.SECTION_WISE_ONLINE_ADMISSION} component={OnlineAdmissionSectionWise} />
            <Route exact path={'/' + ROUTES.GROUP_WISE_ONLINE_ADMISSION} component={OnlineAdmissionGroupWise} />
            <Route exact path={'/' + ROUTES.PAYMENT_STATUS_WISE_ONLINE_ADMISSION} component={OnlineAdmissionPaymentStatusWise} />
            <Route exact path={'/' + ROUTES.GRAPHICAL_VIEW} component={GraphicalView} />
            <Route exact path={'/' + ROUTES.SEAT_PLAN} component={SeatPlan} />
            <Route exact path="/change-password" component={Profile} />
            <Route exact path="/change-password" component={Profile} />
            <Route exact path={'/' + ROUTES.ERROR_PAGE} component={EmptyPage} />


            <Route exact path={'/' + ROUTES.ONLINE_ADMISSION_REPORT_PAID_UNPAID} component={OnlineAdmissionReportPaidUnpaid} />
            <Route exact path={'/' + ROUTES.ONLINE_ADMISSION_GENERAL_REPORT} component={OnlineAdmissionGeneralReport} />
            <Route exact path={'/' + ROUTES.ONLINE_ADMISSION_STUDENT_REGISTRATION} component={OnlineAdmissionStudentRegistration} />
            <Route exact path={'/' + ROUTES.ONLINE_ADMISSION_SPG_PROBLEM_VIEW} component={OnlineAdmissionSpgProblemView} />
            <Route exact path={'/' + ROUTES.ONLINE_ADMISSION_INFO_EDIT} component={OnlineAdmissionInfoEdit} />



            /////////*********Report Customer*******////////////
            <Route exact path={'/' + ROUTES.PERSON_BILL_COLLECTION} component={PersonWiseBillCollection} />
            <Route exact path={'/' + ROUTES.PERSON_WISE_INSTITUTE} component={PersonWiseInstitute} />
            <Route exact path={'/' + ROUTES.INSTITUTE_COUNT_STAT} component={InstituteCountStat} />
            <Route exact path={'/' + ROUTES.INSTITUTE_TRANSACTION_STATUS} component={InstituteTransactionStatus} />
            <Route exact path={'/' + ROUTES.INSTITUTE_CREATE_LIST} component={InstituteCreateList} />

            /////////*********Alumni Student*******////////////
            /////////*********Alumni Student*******////////////


          <Route exact path={'/' + ROUTES.ALUMNI_STUDENT_VIEW} component={AlumniStudentView} />  
          <Route exact path={'/' + ROUTES.ALUMNI_SPG_PROBLEM_VIEW} component={AlumniSpgProblemView} />  

          </Switch>
        </Content>
        <Footer style={{ textAlign: 'center', padding: "15px" }}>
          &copy; Copyright {year}  <a href="https://shebadigital.com/" style={{ color: '#00CA88' }} target="_blank" rel="noopener noreferrer">Sheba Digital Limited</a> | Part of Sheba Group.
        </Footer>
      </Layout>
    </Layout>
  );
}
