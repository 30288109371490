import React, { useEffect, useState } from 'react';
import { Table, Tag, Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, Upload, Skeleton } from 'antd';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';

import { SaveOutlined, UploadOutlined } from '@ant-design/icons';
import _ from 'lodash';

import xlsxParser from 'xlsx-parse-json';

export default function StudentIDUpdateExcel() {
    const [form] = Form.useForm();
    const [tableRowStore, setTableRowStore] = useState([]);
    const updateStudentCustomIdByBatch = useStoreActions((state) => state.student.updateStudentCustomIdByBatch)

    const [fileListx, setFileList] = useState<any>([]);

    const [loading, setloading] = useState<boolean>(false);

    const onChangeFile = ({ fileList: newFileList }) => {
        //console.log("Here")
        setTableRowStore([])
        newFileList[0].status = "done"
        setloading(true)
        xlsxParser
            .onFileSelection(newFileList[0]?.originFileObj)
            .then(data => {
                setloading(false)
                if (data?.['Information'] === undefined) {
                    notification.error({ message: "Please use sample file " })
                    window.location.reload();
                    setTableRowStore([]);
                    return;
                } else if (data?.['Information'].length === 0) {
                    message.error("No student found in the file")
                    window.location.reload();
                    setTableRowStore([]);
                    return;
                }
                let temp = data?.['Information']?.map(function (item, index) {
                    return {
                        key: index,
                        currentStudentId: item['Current Student ID'],
                        newStudentId: item['New Student ID'],
                    }
                })
                setTableRowStore(temp)
                data = ''
                setFileList([])
                newFileList = ''
            });

    };


    const columns: any = [
        {
            title: 'Current Student Id',
            dataIndex: 'currentStudentId',
            key: 'currentStudentId',
        },
        {
            title: 'New Student ID',
            dataIndex: 'newStudentId',
            key: 'newStudentId',
        },
       
        
    ];

    const finalSubmit = (e) => {
        submitRegForm();
    }
    const submitRegForm = () => {

            if (tableRowStore.length == 0) {
                notification.error({ message: "Your Table is empty", description: "" });
                return;
            } else {
                updateStudentCustomIdByBatch(tableRowStore)
                setTimeout(() => {
                    setTableRowStore([])
                }, 1000);
            }
    }

    return (
        <>
            <Form
                layout="vertical"
                id="classConfigInfo"
                form={form}
                onFinish={finalSubmit}
            >
                <Row>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }}>

                        <Row>

                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                <Upload
                                    listType="text"
                                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                    fileList={fileListx}
                                    onChange={onChangeFile}
                                    style={{ maxWidth: 220 }}

                                >
                                    {fileListx.length < 1 && <><Button icon={<UploadOutlined />}>Upload Excel File</Button></>}
                                </Upload>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 2 }} xl={{ span: 2 }}>
                                <Button type="primary" onClick={() => window.open('https://res.cloudinary.com/okkhor/raw/upload/v1738469056/shebaschool/student_id_update.xlsx', '_blank')}>Sample File</Button>
                            </Col>
                        </Row>

                    </Col>
                </Row>
                <Skeleton loading={loading} paragraph={{ rows: 10 }} />
                <br />
                <Row className="m-t-mo-30" style={{ display: tableRowStore?.length > 0 ? 'block' : 'none' }}>
                    <Col span="24">
                        <Table
                            columns={columns}
                            dataSource={tableRowStore}
                            bordered={true}
                            pagination={false}
                            className="p-datatable-responsive-demo"
                        />
                        <div style={{ float: 'right' }} >


                            <Button type="primary" htmlType="submit" icon={<SaveOutlined />} className="m-t-mo-30 mt-30">
                                Save
                            </Button>

                        </div>

                    </Col>
                </Row>
            </Form>
        </>
    )
}