import React, { useEffect } from 'react'
import { Card, Tabs } from 'antd';
import StudentIDUpdateManual from './StudentIDUpdateManual';
import StudentIDUpdateExcel from './StudentIDUpdateExcel';

const { TabPane } = Tabs;

export default function StudentIDUpdate(props) {

    return (
        <>
            <Card title="Student Id Update" >
                <Tabs defaultActiveKey="1" size={"large"}>
                    <TabPane tab="Custom" key="1">
                        <StudentIDUpdateManual />
                    </TabPane>
                    <TabPane tab="Excel" key="2">
                        <StudentIDUpdateExcel />
                    </TabPane>
                </Tabs>
            </Card>
        </>
    )

}