import { Button, Card, Col, Form, Input, InputNumber, Row, Select, Space } from 'antd';
import React, { useEffect } from 'react'
import { useStoreActions } from '../../../store/hooks/easyPeasy';
import { Link } from "react-router-dom";
import { ROUTES } from '../../../contents/routes';
import { SelectDistrict } from '../../select/SelectDistrict';
import { useStoreState } from 'easy-peasy';
const { Option } = Select;

export default function InstituteCreate() {

const [form] = Form.useForm();

const fetchInstituteId = useStoreActions((state) => state.customerSupport.fetchInstituteId);
const maxInstituteId = useStoreState<any>((state) => state.customerSupport.instituteId);

const saveInstitute = useStoreActions((state) => state.customerSupport.createInstitute);



const formSubmit=(value)=>{
    value.instituteId=maxInstituteId;
    saveInstitute(value);
}

const onChangeInstituteType = (e) => {
    fetchInstituteId(e);
}

 useEffect(() => {
    form.setFieldsValue({
        instituteId: maxInstituteId
    })
    }, maxInstituteId);




  return (
    <>
    
    <Card title="Institute Registration">

    <Row>
    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 20, offset: 3 }} xl={{ span: 20, offset: 3 }}>
                <Form
                    layout="vertical"
                    onFinish={formSubmit}
                    id="partner-info"
                    form={form}
                >
                  
                  <Row gutter={8}>
                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="instituteType"
                                label="Institute Type"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please insert institute type" },
                                ]}
                            >
                             <Select placeholder="Select Year" allowClear onChange={(e) => {onChangeInstituteType(e)}}>
                                <Option value={1}>College</Option>
                                <Option value={2}>School</Option>
                                <Option value={3}>Primary Education</Option>
                            </Select>
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="instituteId"
                                label="Institute Id"
                                className="title-Text"
                            >
                                {maxInstituteId}
                            </Form.Item>
                        </Col>
                        
                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="instituteName"
                                label="Institute Name "
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please insert instituteName" },
                                ]}
                            >
                                <Input placeholder="Institute Name" />
                            </Form.Item>
                        </Col>
                        
                        <Col xs={24} sm={24} md={24} lg={6} xl={6}></Col>

 
                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="address"
                                label="Address"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please input Address" },
                                ]}
                            >
                                 <Input.TextArea placeholder="Address" />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="eiinNo"
                                label="EIIN No"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please Enter EIIN No" },
                                ]}
                            >
                                <Input placeholder="EIIN No"/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="instituteEmail"
                                label="Institute Email"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please insert Institute Email" },
                                ]}
                            >
                                <Input placeholder="Institute Email" />
                            </Form.Item>
                        </Col>
                        
                        <Col xs={24} sm={24} md={24} lg={6} xl={6}></Col>

                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="contactNo"
                                label="Contact No"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please input Contact No" },
                                ]}
                            >
                                 <Input placeholder="Contact No" />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="adminName"
                                label="Admin Name"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please Enter Admin Name" },
                                ]}
                            >
                                <Input placeholder="Admin Name"/>
                            </Form.Item>
                        </Col>
                        
                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="academicYear"
                                label="Academic Year"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please insert Academic Year" },
                                ]}
                            >
                                <Input placeholder="Academic Year" />
                            </Form.Item>
                        </Col>
                    


                        <Col xs={24} sm={24} md={24} lg={16} xl={16}> </Col>
                        <Col xs={24} sm={24} md={24} lg={2} xl={2}>
                            <Space size="small" style={{float:"right"}}>
                                <Button type="primary" htmlType="submit" style={{ height: 40 }} >
                                    Save
                                </Button>
                            </Space>
                        </Col>
                        </Row>
                   
                </Form>
                
                </Col>
                
                </Row>

            </Card>
    
    </>
  )
}
