import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, TimePicker, Skeleton, DatePicker } from 'antd'
import { DeleteOutlined, DownloadOutlined, EditOutlined, FileExcelOutlined, SaveOutlined, SearchOutlined } from '@ant-design/icons';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import Table from "../../../../contents/AntTableResponsive";
import moment from 'moment';
import { SelectPeriod } from '../../../select/SelectPeriod';
import { SelectClassConfig } from '../../../select/SelectClassConfig';
import { SelectAcademicYear } from '../../../select/SelectAcademicYear';
import $ from 'jquery';
import jsPDF from "jspdf";
import { pdfDataL, ldatepdf, lpowerdbypdf } from '../../../utils/pdf';
import ReactExport from "react-export-excel";
require('jspdf-autotable');
const ExcelFile: any = ReactExport.ExcelFile;
const ExcelSheet: any = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn: any = ReactExport.ExcelFile.ExcelColumn;

let fd = '';
let td = '';
export default function SectionWiseSummaryAttendance() {

    const [form] = Form.useForm();
    const { Option } = Select;
    const format = 'HH:mm';
    const fetchStudentAttendanceAllPeriod = useStoreActions((state) => state.student.fetchStudentAttendanceAllPeriod);
    const fetchsectionDateToDateSummary = useStoreActions((state) => state.student.fetchsectionDateToDateSummary);
    const loading = useStoreState((state) => state.student.loading)
    const sectionDateToDateSummary = useStoreState((state) => state.student.sectionDateToDateSummary)
    const [monthName, setMonthName] = useState("");
    const [yearName, setYearName] = useState("");


    const sectionWiseMonthlySummary = (value) => {
        value.fromDate = moment(value?.fromDate).format("YYYY-MM-DD");
        value.toDate = moment(value?.toDate).format("YYYY-MM-DD")
        fd = moment(value?.fromDate).format("YYYY-MM-DD");
        td = moment(value?.toDate).format("YYYY-MM-DD");

        fetchsectionDateToDateSummary(value);

    }

    useEffect(() => {
        fetchStudentAttendanceAllPeriod();
    }, []);


    const columns = [
        { title: 'ID', dataIndex: 'customStudentId', key: 'customStudentId', showOnResponse: true, showOnDesktop: true },
        { title: 'Student Name', dataIndex: 'studentName', key: 'studentName', showOnResponse: true, showOnDesktop: true },
        { title: 'Student Roll', dataIndex: 'studentRoll', key: 'studentRoll', showOnResponse: true, showOnDesktop: true },
        { title: 'Total Attendance', dataIndex: 'totalAttendance', key: 'totalAttendance', showOnResponse: true, showOnDesktop: true },
        { title: 'Present', dataIndex: 'present', key: 'present', showOnResponse: true, showOnDesktop: true },
        { title: 'Absent', dataIndex: 'absent', key: 'absent', showOnResponse: true, showOnDesktop: true },
    ];

    function exportPdf() {

        var doc = new jsPDF("l", "mm", "a4");

        var details = `Section Summary of ${$(".sectionSelect").text()} from ${fd} to ${td}`;
        pdfDataL(doc, "");
        doc.text(details, 150, 40, 'center');
        var totalPagesExp = "{total_pages_count_string}";

        var pageContent = function (data) {
            // FOOTER
            var str = lpowerdbypdf + data.pageCount;
            if (typeof doc.putTotalPages === 'function') {
                str = str + " of " + totalPagesExp + ldatepdf;
            }
            doc.setFontSize(8);
            var pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
            doc.text(str, data.settings.margin.right, pageHeight - 10);
        };


        var col = [
            "ID",
            "Student Name",
            "Roll",
            "Total Attendance",
            "Present",
            "Absent"
        ].filter(Boolean);

        var rows: any = [];


        sectionDateToDateSummary.forEach(element => {
            var temp: any = [
                element.customStudentId,
                element.studentName,
                element.studentRoll,
                element.totalAttendance,
                element.present,
                element.absent,
            ];
            rows.push(temp);
        });


        let first = doc.autoTable.previous;
        doc.autoTable(col, rows, {
            startY: 45,
            showHeader: "firstPage",
            theme: 'grid',
            headerStyles: {
                // fillColor: [105, 105, 105],
                // textColor: [255, 255, 255],
                // fontSize: 10
                lineWidth: .01,
                lineColor: [224, 224, 224]
            },
            columnStyles: {
                guardianMobile: {
                    halign: "left",
                    fontSize: 8,
                    columnWidth: 250
                }
            },
            addPageContent: pageContent,
        });


        if (typeof doc.putTotalPages === 'function') {
            doc.putTotalPages(totalPagesExp);
        }
        doc.setPage(1 + doc.internal.getCurrentPageInfo().pageNumber - doc.autoTable.previous.pageCount);
        doc.save(details + ".pdf");

    }

    return <>
        <Card title="Section Attendance Summary">
            <Row>
                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }}>
                    <Form
                        layout="vertical"
                        id="classConfigInfo"
                        form={form}
                        onFinish={sectionWiseMonthlySummary}
                    >
                        <Row>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                                <Form.Item
                                    name="academicYear"
                                    label="Academic Year"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select year" },
                                    ]}
                                >
                                    <SelectAcademicYear />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                                <Form.Item
                                    name="classConfigurationId"
                                    label="Select Section"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select section" },
                                    ]}
                                >
                                    <SelectClassConfig />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                                <Form.Item
                                    name="periodId"
                                    label="Period"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select period" },
                                    ]}
                                >
                                    <SelectPeriod />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                                <Form.Item
                                    name="fromDate"
                                    label="From Date"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please enter from date" },
                                    ]}
                                >

                                    <DatePicker style={{ width: '100%' }} placeholder="Select Date" format={"DD/MM/YYYY"} />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                                <Form.Item
                                    name="toDate"
                                    label="To Date"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please enter to date" },
                                    ]}
                                >

                                    <DatePicker style={{ width: '100%' }} placeholder="Select Date" format={"DD/MM/YYYY"} />
                                </Form.Item>
                            </Col>

                        </Row>
                        <Row>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }}>
                                <div style={{ float: "right" }}>
                                    <Space size="small" >
                                        <Button type="primary" htmlType="submit" className='mt-0' icon={<SearchOutlined />} >
                                            Search
                                        </Button>
                                    </Space>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
            <Skeleton loading={loading} paragraph={{ rows: 10 }} />
            <Row className='mt-30' style={{ display: sectionDateToDateSummary.length > 0 ? '' : 'none' }}>
                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }}>
                    <Table
                        antTableProps={{
                            showHeader: true,
                            columns: columns,
                            dataSource: sectionDateToDateSummary,
                            filterData: sectionDateToDateSummary,
                            pagination: true,
                            bordered: true,
                            rowKey: "customStudentId",
                        }}
                        mobileBreakPoint={768}

                    />
                </Col>
                <Col span={24}>

                    <Space size="small" style={{ float: "right" }}>
                        <Button type="primary" icon={<DownloadOutlined />} onClick={() => exportPdf()} >PDF Download</Button>
                        <ExcelFile
                            element={<Button type="primary" icon={<FileExcelOutlined />} >
                                Download Excel
                            </Button>}
                            filename={`Section Summary of ${$(".sectionSelect").text()} from ${fd} to ${td}`}
                        >
                            <ExcelSheet data={sectionDateToDateSummary} name=" ">
                                <ExcelColumn label="Student Id" value="customStudentId" />
                                <ExcelColumn label="Student Name" value="studentName" />
                                <ExcelColumn label="Student Roll" value="studentRoll" />
                                <ExcelColumn label="Attendance Taken" value="totalAttendance" />
                                <ExcelColumn label="Total Present" value="present" />
                                <ExcelColumn label="Total Absent" value="absent" />
                            </ExcelSheet>

                        </ExcelFile>
                    </Space>
                </Col>
            </Row>
        </Card>
    </>
}