import React, { useEffect, useState } from 'react';
import { Tag, Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, DatePicker, Skeleton } from 'antd';
import { EditOutlined, SaveOutlined, SearchOutlined } from '@ant-design/icons';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import Table from "../../../../contents/AntTableResponsive";
import { SelectClassConfig } from '../../../select/SelectClassConfig';
import { Typography } from 'antd';
import { SelectAcademicYear } from '../../../select/SelectAcademicYear';

export default function StudentIDUpdateManual() {

    const [searchStudentForm] = Form.useForm();
    const fetchStudentListForStudentIDUpdate = useStoreActions((state) => state.student.fetchStudentListForStudentIDUpdate);
    const studentListForStudentIDUpdate = useStoreState((state) => state.student.studentListForStudentIDUpdate);
    const updateStudentListIDInfo = useStoreActions((state) => state.student.updateStudentListIDInfo);
    const [classconfigid, setClassConfigID] = useState<any>();
    const [studentListForUpdate, setStudentListForUpdate] = useState<any>();
    const [selectedRowKeys, setselectedRowKeys] = useState([]);
    const [selectedValue, setselectedValue] = useState([]);
    const { Title } = Typography;
    const loading = useStoreState((state) => state.student.loading);

    const searchStudentList = (value) => {
        fetchStudentListForStudentIDUpdate(value);
        setClassConfigID(value);
    }

    useEffect(() => {
        setStudentListForUpdate(studentListForStudentIDUpdate.map((item: any, index) => ({...item,index:index})));

    }, [studentListForStudentIDUpdate])

    const onchangeValue: any = (key, data, index) => (e: React.ChangeEvent<HTMLInputElement>) => {
        const newData: any = [...studentListForUpdate];
        newData[index][key] = e.target.value;
        setStudentListForUpdate(newData);
    };
    const columns = [
        {
            title: 'ID',
            dataIndex: 'customStudentId',
            key: 'customStudentId',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <>
                    <Input onChange={onchangeValue("customStudentId", record, record.index)} defaultValue={record.customStudentId} />
                </>
            )
        },
        {
            title: 'Student Name',
            dataIndex: 'studentName',
            key: 'studentName',
            showOnResponse: true,
            showOnDesktop: true,

        },
        {
            title: 'Roll',
            dataIndex: 'studentRoll',
            key: 'studentRoll',
            showOnResponse: true,
            showOnDesktop: true,
        },

        {
            title: 'Group',
            dataIndex: 'groupName',
            key: 'groupName',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Session',
            dataIndex: 'academicYear',
            key: 'academicYear',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Father',
            dataIndex: 'fatherName',
            key: 'fatherName',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Mother',
            dataIndex: 'motherName',
            key: 'motherName',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Gender',
            dataIndex: 'studentGender',
            key: 'studentGender',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Religion',
            dataIndex: 'studentReligion',
            key: 'studentReligion',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Blood Group',
            dataIndex: 'bloodGroup',
            key: 'bloodGroup',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Birth Date',
            dataIndex: 'studentDOB',
            key: 'studentDOB',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Mobile No.',
            dataIndex: 'guardianMobile',
            key: 'guardianMobile',
            showOnResponse: true,
            showOnDesktop: true,
        }
    ];
    const updateStudentInfo = (e) => {
        let details: any = [];
        selectedValue.map((item: any) => {
            details.push({
                "customStudentId": item.customStudentId,
                "studentId": `${item.studentId}`
            })
        })
        let postData: any = {
            classConfig: classconfigid,
            payload: details
        }

        updateStudentListIDInfo(postData);
        setselectedRowKeys([]);
        setselectedValue([]);
    }

    const onSelectChange = (selectedRowKeys, value) => {
        setselectedRowKeys(selectedRowKeys);
        setselectedValue(value);
    };

    const rowSelection = {
        preserveSelectedRowKeys: true,
        selectedRowKeys,
        onChange: onSelectChange,
    };

    return (
        <>
            <>
                <Row>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 20, offset: 4 }} xl={{ span: 20, offset: 4 }}>
                        <Form
                            layout="vertical"
                            id="sessionYearInfo"
                            onFinish={searchStudentList}
                            form={searchStudentForm}
                        >
                            <Row>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                    <Form.Item
                                        name="academicYear"
                                        label="Academic Year"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select year" },
                                        ]}
                                    >
                                        <SelectAcademicYear />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                    <Form.Item
                                        name="classconfigid"
                                        label="Section"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select section" },
                                        ]}
                                    >
                                        <SelectClassConfig style={{ width: "100%" }} />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>

                                    <Button type="primary" htmlType="submit" icon={<SearchOutlined />} >
                                        Search
                                    </Button>

                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
                <Row>
                    <Skeleton loading={loading} paragraph={{ rows: 15 }} />
                    <Col span={24}>
                        <div style={{ display: studentListForStudentIDUpdate.length > 0 ? 'block' : 'none' }}>
                            <div className="datatable-responsive-demo" style={{ width: "100%", overflow: "auto" }}>
                                <Table
                                    antTableProps={{
                                        showHeader: true,
                                        columns,
                                        rowSelection: rowSelection,
                                        dataSource: studentListForUpdate,
                                        filterData: studentListForUpdate,
                                        pagination: false,
                                        bordered: true,
                                        rowKey: "studentId",
                                    }}
                                    mobileBreakPoint={768}

                                />
                            </div>
                            <Row style={{ display: selectedValue.length > 0 ? "" : "none" }}>
                                <Col span="24">
                                    <Button type="primary" icon={<EditOutlined />} style={{ float: 'right' }} onClick={(e) => updateStudentInfo(e)}>
                                        Update
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </>
        </>
    )
}